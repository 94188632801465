.footer{
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer_container{
    padding: 2rem 0 6rem;
}

.footer_title,
.footer_link{
    color: var(--title-color);
}
.footer_logo{
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

.footer_title{
    text-align: center;
    margin-bottom: var(--mb-2);
}
.footer_link:hover{
    color: var(--title-color-dark);
}

.footer_list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer_social{
    display: flex;
    justify-content: center;
    column-gap: 1.125rem;
    
}

.footer_social-link{
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.25rem;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer_social-link:hover{
    background-color: var(--title-color-dark);
}

.footer_copy{
    display: block;
    margin-top: 4.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--small-font-size);
}



/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
   .footer_social-link{
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 1rem;
   }
   .footer_logo{
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto;
}
  }

  /* For medium devices */
@media screen and (max-width: 768px) {
    .footer_logo{
        width: 90px;
        height: 90px;
        display: block;
        margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 576px) {
    .footer_logo{
        width: 80px;
        height: 80px;
        display: block;
        margin: 0 auto;
    }
    
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .footer_logo{
        width: 70px;
        height: 70px;
        display: block;
        margin: 0px auto;
    
    }
  }
  
  